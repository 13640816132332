import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  LinearProgress,
  CircularProgress
} from '@mui/material';
import {
  Person,
  RestaurantMenu,
  Message,
  EmailOutlined
} from '@mui/icons-material';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  ResponsiveContainer,
  Tooltip,
  Legend 
} from 'recharts';
import Navigation from '../components/Navigation';

const Dashboard = () => {
  const [userLoading, setUserLoading] = useState(true);
  const [userError, setUserError] = useState(null);
  const [userRegistrationData, setUserRegistrationData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  
  const [tiffinLoading, setTiffinLoading] = useState(true);
  const [tiffinError, setTiffinError] = useState(null);
  const [tiffinRegistrationData, setTiffinRegistrationData] = useState([]);
  const [totalTiffinProviders, setTotalTiffinProviders] = useState(0);

  useEffect(() => {
    fetchUserData();
    fetchTiffinData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch('https://foodlesadmin.in/api/getUsers.php');
      const data = await response.json();

      if (data.status && data.data.users) {
        const registrationsByDate = processRegistrationData(data.data.users);
        setUserRegistrationData(registrationsByDate);
        setTotalUsers(data.data.pagination.totalRecords);
      } else {
        setUserError(data.message || 'Failed to fetch user data');
      }
    } catch (err) {
      setUserError('Failed to fetch user data');
    } finally {
      setUserLoading(false);
    }
  };

  const fetchTiffinData = async () => {
    try {
      const response = await fetch('https://foodlesadmin.in/api/admin/getTiffingProviderForAdmin.php');
      const data = await response.json();

      if (data.status && data.data.providers) {
        const registrationsByDate = processRegistrationData(data.data.providers);
        setTiffinRegistrationData(registrationsByDate);
        setTotalTiffinProviders(data.data.pagination.totalRecords);
      } else {
        setTiffinError(data.message || 'Failed to fetch tiffin provider data');
      }
    } catch (err) {
      setTiffinError('Failed to fetch tiffin provider data');
    } finally {
      setTiffinLoading(false);
    }
  };

  const processRegistrationData = (data) => {
    const dateMap = new Map();
    data.forEach(item => {
      const date = item.created_at.split(' ')[0];
      dateMap.set(date, (dateMap.get(date) || 0) + 1);
    });

    return Array.from(dateMap, ([date, count]) => ({
      date,
      registrations: count
    })).sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            backgroundColor: 'white',
            padding: 2,
            border: '1px solid #ccc',
            borderRadius: 1
          }}
        >
          <Typography variant="body2">
            <strong>Date:</strong> {label}
          </Typography>
          <Typography variant="body2">
            <strong>Registrations:</strong> {payload[0].value}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  // Dynamic metrics data
  const metrics = [
    { 
      title: "Total Users", 
      value: totalUsers, 
      change: "Registered Users", 
      icon: Person, 
      color: "#e3f2fd" 
    },
    { 
      title: "Total Providers", 
      value: totalTiffinProviders, 
      change: "Tiffin Providers", 
      icon: RestaurantMenu, 
      color: "#fff3e0" 
    },
    { 
      title: "Messages", 
      value: "", 
      change: "Chat Feature", 
      icon: Message, 
      color: "#f3e5f5" 
    },
    { 
      title: "Notifications", 
      value: "", 
      change: "Email/SMS", 
      icon: EmailOutlined, 
      color: "#e8f5e9" 
    }
  ];

  return (
    <Navigation>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {/* Metrics */}
          {metrics.map((metric, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card sx={{ bgcolor: metric.color, height: '100%' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                      <Typography color="text.secondary" variant="subtitle2">
                        {metric.title}
                      </Typography>
                      <Typography variant="h4" sx={{ my: 1 }}>
                        {metric.value}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                      >
                        {metric.change}
                      </Typography>
                    </Box>
                    <metric.icon sx={{ fontSize: 40, opacity: 0.7 }} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}

          {/* User Registration Chart */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="User Registration" subheader="Daily registration statistics" />
              <CardContent>
                <Box sx={{ height: 300 }}>
                  {userLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                      <CircularProgress />
                    </Box>
                  ) : userError ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                      <Typography color="error">{userError}</Typography>
                    </Box>
                  ) : (
                    <ResponsiveContainer>
                      <BarChart data={userRegistrationData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                          dataKey="date" 
                          angle={-45}
                          textAnchor="end"
                          height={70}
                        />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Bar 
                          dataKey="registrations" 
                          name="User Registrations"
                          fill="#1976d2" 
                          radius={[4, 4, 0, 0]}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  )}
                </Box>
                {!userLoading && !userError && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      Total Users: {totalUsers}
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* Tiffin Provider Registration Chart */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Tiffin Provider Registration" subheader="Daily registration statistics" />
              <CardContent>
                <Box sx={{ height: 300 }}>
                  {tiffinLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                      <CircularProgress />
                    </Box>
                  ) : tiffinError ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                      <Typography color="error">{tiffinError}</Typography>
                    </Box>
                  ) : (
                    <ResponsiveContainer>
                      <BarChart data={tiffinRegistrationData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                          dataKey="date" 
                          angle={-45}
                          textAnchor="end"
                          height={70}
                        />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Bar 
                          dataKey="registrations" 
                          name="Tiffin Provider Registrations"
                          fill="#ff9800" 
                          radius={[4, 4, 0, 0]}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  )}
                </Box>
                {!tiffinLoading && !tiffinError && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      Total Providers: {totalTiffinProviders}
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Navigation>
  );
};

export default Dashboard;